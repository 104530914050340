import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { MsalAuthenticationProvider } from "./authentication/MsalAuthenticationProvider";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <MsalAuthenticationProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </MsalAuthenticationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
