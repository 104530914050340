import { useQuery } from "react-query";
import { BackendVersionApi } from "./backendVersionApi";

export const useBackendVersion = () => {
  const { data: backendVersion } = useQuery(
    "backend-version",
    () => BackendVersionApi.getVersion(),
    { staleTime: Infinity }
  );

  return {
    backendVersion,
  };
};
