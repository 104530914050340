import React, { FunctionComponent, useState } from "react";
import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  ButtonItem,
  MenuDropdown,
  MenuDropdownButton,
  MenuDropdownItems,
  TextIcon,
} from "@bbprojects/react-components";
import {
  ChevronDownIcon,
  LogoutIcon,
  TranslateIcon,
} from "@heroicons/react/solid";
import routes from "../../routes/routes";
import LanguageSelectorModal from "../../features/modals/LanguageSelectorModal/LanguageSelectorModal";
import { useTranslation } from "react-i18next";
import { useLocation, matchPath } from "react-router";

const Header: FunctionComponent = () => {
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const location = useLocation();
  const { instance } = useMsal();
  const account = instance.getActiveAccount() as AccountInfo;

  const isRouteMatch = (routePath: string, pathname: string) => {
    const match = matchPath({ path: routePath }, pathname);

    return !!match;
  };

  const route = routes.find(
    (r) =>
      r.path === location.pathname || isRouteMatch(r.path, location.pathname)
  );

  const { t } = useTranslation();

  const closeLanguageModal = () => {
    setShowLanguageModal(false);
  };

  return (
    <>
      <div className="flex items-center h-12 px-4 shadow bg-primary">
        {route && (
          <TextIcon Icon={route.Icon} text={t(route.title)} color="black" />
        )}
        <div className="flex relative ml-auto">
          <MenuDropdown>
            {({ open }) => (
              <>
                <MenuDropdownButton className="flex items-center hover:bg-primary p-2 rounded select-none">
                  <TextIcon
                    text={account.name || account.username}
                    Icon={ChevronDownIcon}
                    iconSize={5}
                    iconRight
                  />
                </MenuDropdownButton>
                <div className="dropdown-items">
                  <MenuDropdownItems open={open}>
                    <ButtonItem
                      Icon={TranslateIcon}
                      text={t("Header.ChangeLanguage")}
                      onClick={() => setShowLanguageModal(true)}
                    />
                    <ButtonItem
                      Icon={LogoutIcon}
                      text={t("Header.Logout")}
                      onClick={() => instance.logout()}
                    />
                  </MenuDropdownItems>
                </div>
              </>
            )}
          </MenuDropdown>
        </div>
      </div>
      <LanguageSelectorModal
        isOpen={showLanguageModal}
        onSubmit={closeLanguageModal}
        onClose={closeLanguageModal}
      />
    </>
  );
};

export default Header;
