import client from "./api/client";
import { loginRequest } from "./authentication/authConfig";
import { authInstance } from "./authentication/authInstance";

const acquireTokenSilent = async (): Promise<string> => {
  let token = "";
  try {
    const tokenResult = await authInstance.acquireTokenSilent(loginRequest);
    token = tokenResult.idToken;
  } catch (error) {
    authInstance.acquireTokenRedirect(loginRequest);
  }

  return token;
};

const interceptor = {
  setupInterceptors: () => {
    client.interceptors.request.use(async (config) => {
      const token = await acquireTokenSilent();
      if (!config.headers) {
        config.headers = {};
      }
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    });
  },
};

export default interceptor;
