import React, { FunctionComponent } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionButton,
  ButtonItem,
  Icon,
  IconChevronToggle,
  SidebarBody,
  SidebarHeader,
  SidebarLayout,
  TextDescriptor,
  TextHeading,
} from "@bbprojects/react-components";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  ArchiveIcon,
  TicketIcon,
  LockClosedIcon,
  TagIcon,
  UserGroupIcon,
  TableIcon,
  CurrencyDollarIcon,
  ClipboardListIcon,
  TemplateIcon,
  LocationMarkerIcon,
  CreditCardIcon,
  CalendarIcon,
} from "@heroicons/react/solid";
import Logo from "./Logo";
import Version from "./Version";
import { useTranslation } from "react-i18next";
import { useAuthorizationContext } from "../../authentication/AuthorizationProvider";
import { configurationRoutes } from "../constants/configurationRoutes";

const SideBar: FunctionComponent = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { permissions } = useAuthorizationContext();

  const classes = {
    textDescriptor: "",
    accordionItems: "",
    accordionButton:
      "w-full h-full transition ease-in-out duration-300 hover:bg-opacity-darker",
  };

  const isConfigurationSectionEnabled =
    permissions.deliveryMethods?.readDeliveryMethodsTable ||
    permissions.productCategories?.readProductCategories ||
    permissions.ticketTemplates?.readTicketTemplatesTable ||
    permissions.salesChannels?.readSalesChannelsTable ||
    permissions.salesChannelUnits?.readSalesChannelUnitsTable ||
    permissions.checkpoints?.readCheckpointsTable ||
    permissions.checkpointTypes?.readCheckpointTypesTable ||
    permissions.paymentMethods?.readPaymentMethodsTable ||
    permissions.paymentProviders?.readPaymentProvidersTable;

  const getActiveClassName = (props: { isActive: boolean }) => {
    return props.isActive ? "active-sidebar-item" : undefined;
  };

  return (
    <div className="flex items-center">
      <SidebarLayout>
        <SidebarHeader>
          <Link to="/">
            <div className="mt-4 mb-8">
              <TextHeading text="" Icon={Logo} textCenter={true} />
            </div>
          </Link>
        </SidebarHeader>
        <SidebarBody>
          <div>
            <TextDescriptor
              text={t("SideBar.Sections").toUpperCase()}
              className="ml-4"
            />
            {permissions.tickets?.readTicketTable && (
              <NavLink
                to="/tickets"
                className={({ isActive }) =>
                  isActive ||
                  location.pathname.includes("/tickets") ||
                  location.pathname.includes("/purchases")
                    ? "active-sidebar-item"
                    : undefined
                }
                data-testid="navTickets"
              >
                <ButtonItem text={t("Tickets")} Icon={TicketIcon} />
              </NavLink>
            )}
            {permissions.tickets?.createBulkPurchase && (
              <NavLink
                to="/bulk-tickets"
                className={getActiveClassName}
                data-testid="navBulkPurchase"
              >
                <ButtonItem text={t("BulkPurchases")} Icon={TicketIcon} />
              </NavLink>
            )}
            {permissions.customers?.readCustomerTable && (
              <NavLink
                to="/customers"
                className={getActiveClassName}
                data-testid="navCustomers"
              >
                <ButtonItem text={t("Customers")} Icon={UserGroupIcon} />
              </NavLink>
            )}
            {permissions.accounts?.readAccountsTable && (
              <NavLink
                to="/customer-accounts"
                className={getActiveClassName}
                data-testid="navAccounts"
              >
                <ButtonItem text={t("Accounts")} Icon={LockClosedIcon} />
              </NavLink>
            )}
            {permissions.licensePlates?.readLicensePlatesTable && (
              <NavLink
                to="/license-plates"
                className={getActiveClassName}
                data-testid="navLicensePlates"
              >
                <ButtonItem text={t("LicensePlates")} Icon={TableIcon} />
              </NavLink>
            )}
            {permissions.products?.readProductsTable && (
              <NavLink
                to="/products"
                className={getActiveClassName}
                data-testid="navProducts"
              >
                <ButtonItem text={t("Products")} Icon={ArchiveIcon} />
              </NavLink>
            )}
            {permissions.financialTransactions
              ?.readFinancialTransactionsTable && (
              <NavLink
                to="/financial-transactions"
                className={getActiveClassName}
                data-testid="navFinancialTransactions"
              >
                <ButtonItem
                  text={t("FinancialTransactions")}
                  Icon={CurrencyDollarIcon}
                />
              </NavLink>
            )}
            {permissions.shareholderMeetings?.readShareholderMeetingsTable && (
              <NavLink
                to="/shareholder-meetings"
                className={getActiveClassName}
                data-testid="navShareholderMeetings"
              >
                <ButtonItem
                  text={t("ShareholderMeetings")}
                  Icon={CalendarIcon}
                />
              </NavLink>
            )}
            {isConfigurationSectionEnabled ? (
              <Accordion defaultOpen={configurationRoutes.includes(pathname)}>
                {({ open }) => (
                  <>
                    <AccordionButton>
                      <div className={classes.accordionButton}>
                        <div className="w-full h-full transition ease-in-out duration-300 hover:bg-opacity-darker">
                          <div className="flex items-center overflow-hidden px-4 py-2">
                            <Icon
                              Icon={ClipboardListIcon}
                              className="flex-none w-4 h-4 text-black-light mr-1 mr-3"
                            />
                            <div
                              data-testid="navConfiguration"
                              className="truncate font-primary text-base text-black-light"
                            >
                              {t("Configuration")}
                            </div>
                            <IconChevronToggle isOpen={!open} right />
                          </div>
                        </div>
                      </div>
                    </AccordionButton>
                    <AccordionBody>
                      <div className="pl-4">
                        {permissions.productCategories
                          ?.readProductCategories && (
                          <NavLink
                            to="/product-categories"
                            className={({ isActive }) =>
                              isActive ? "active-sidebar-item" : undefined
                            }
                            data-testid="navProductCategories"
                          >
                            <ButtonItem
                              text={t("ProductCategories")}
                              Icon={TagIcon}
                            />
                          </NavLink>
                        )}
                        {permissions.deliveryMethods
                          ?.readDeliveryMethodsTable && (
                          <NavLink
                            to="/delivery-methods"
                            className={({ isActive }) =>
                              isActive ? "active-sidebar-item" : undefined
                            }
                            data-testid="navDeliveryMethods"
                          >
                            <ButtonItem
                              text={t("DeliveryMethods")}
                              Icon={TagIcon}
                            />
                          </NavLink>
                        )}

                        {permissions.ticketTemplates
                          ?.readTicketTemplatesTable && (
                          <NavLink
                            to="/ticket-templates"
                            className={({ isActive }) =>
                              isActive ? "active-sidebar-item" : undefined
                            }
                            data-testid="navTicketTemplates"
                          >
                            <ButtonItem
                              text={t("TicketTemplates")}
                              Icon={TemplateIcon}
                            />
                          </NavLink>
                        )}

                        {permissions.salesChannels?.readSalesChannelsTable && (
                          <NavLink
                            to="/sales-channels"
                            className={({ isActive }) =>
                              isActive ? "active-sidebar-item" : undefined
                            }
                            data-testid="navSalesChannel"
                          >
                            <ButtonItem
                              text={t("SalesChannels")}
                              Icon={ArchiveIcon}
                            />
                          </NavLink>
                        )}

                        {permissions.salesChannelUnits
                          ?.readSalesChannelUnitsTable && (
                          <NavLink
                            to="/sales-channel-units"
                            className={({ isActive }) =>
                              isActive ? "active-sidebar-item" : undefined
                            }
                            data-testid="navSalesChannelUnit"
                          >
                            <ButtonItem
                              text={t("SalesChannelUnits")}
                              Icon={ArchiveIcon}
                            />
                          </NavLink>
                        )}

                        {permissions.checkpoints?.readCheckpointsTable && (
                          <NavLink
                            to="/checkpoints"
                            className={({ isActive }) =>
                              isActive ? "active-sidebar-item" : undefined
                            }
                            data-testid="navCheckpoints"
                          >
                            <ButtonItem
                              text={t("Checkpoints")}
                              Icon={LocationMarkerIcon}
                            />
                          </NavLink>
                        )}

                        {permissions.checkpointTypes
                          ?.readCheckpointTypesTable && (
                          <NavLink
                            to="/checkpoint-types"
                            className={({ isActive }) =>
                              isActive ? "active-sidebar-item" : undefined
                            }
                            data-testid="navCheckpointTypes"
                          >
                            <ButtonItem
                              text={t("CheckpointTypes")}
                              Icon={LocationMarkerIcon}
                            />
                          </NavLink>
                        )}

                        {permissions.paymentMethods
                          ?.readPaymentMethodsTable && (
                          <NavLink
                            to="/payment-methods"
                            className={({ isActive }) =>
                              isActive ? "active-sidebar-item" : undefined
                            }
                            data-testid="navPaymentMethods"
                          >
                            <ButtonItem
                              text={t("PaymentMethods")}
                              Icon={CreditCardIcon}
                            />
                          </NavLink>
                        )}

                        {permissions.paymentProviders
                          ?.readPaymentProvidersTable && (
                          <NavLink
                            to="/payment-providers"
                            className={({ isActive }) =>
                              isActive ? "active-sidebar-item" : undefined
                            }
                            data-testid="navPaymentProviders"
                          >
                            <ButtonItem
                              text={t("PaymentProviders")}
                              Icon={CreditCardIcon}
                            />
                          </NavLink>
                        )}

                        {permissions.validitySchedules
                          ?.readValiditySchedulesTable && (
                          <NavLink
                            to="/validity-schedules"
                            className={({ isActive }) =>
                              isActive ? "active-sidebar-item" : undefined
                            }
                            data-testid="navValiditySchedules"
                          >
                            <ButtonItem
                              text={t("ValiditySchedules")}
                              Icon={CalendarIcon}
                            />
                          </NavLink>
                        )}
                      </div>
                    </AccordionBody>
                  </>
                )}
              </Accordion>
            ) : null}
          </div>
        </SidebarBody>
        <Version />
      </SidebarLayout>
    </div>
  );
};

export default SideBar;
