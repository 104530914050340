import {
  ArchiveIcon,
  LockClosedIcon,
  TableIcon,
  TagIcon,
  TicketIcon,
  UserGroupIcon,
  CurrencyDollarIcon,
  TemplateIcon,
  LocationMarkerIcon,
  CreditCardIcon,
  CalendarIcon,
} from "@heroicons/react/solid";
import {
  LazyExoticComponent,
  FunctionComponent,
  ComponentType,
  CSSProperties,
  lazy,
} from "react";
import { IPermissions } from "../authentication/role-permissions/IPermissions";

interface IRoute {
  userHasCorrectPermissions: (permissions: IPermissions) => boolean | undefined;
  path: string;
  component: LazyExoticComponent<FunctionComponent<any>>;
  exact: boolean;
  title: string;
  Icon: ComponentType<{ className?: string; style?: CSSProperties }>;
}

const ticketsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.tickets?.readTicketTable,
  path: "/tickets",
  component: lazy(() => import("../features/tickets/Tickets")),
  exact: true,
  title: "Tickets",
  Icon: TicketIcon,
};
const bulkTicketsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.tickets?.createBulkPurchase,
  path: "/bulk-tickets",
  component: lazy(() => import("../features/bulk-purchase/BulkPurchase")),
  exact: true,
  title: "BulkPurchases",
  Icon: TicketIcon,
};
const customersRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.customers?.readCustomerTable,
  path: "/customers",
  component: lazy(() => import("../features/customers/Customers")),
  exact: true,
  title: "Customers",
  Icon: UserGroupIcon,
};
const customerAccountsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.accounts?.readAccountsTable,
  path: "/customer-accounts",
  component: lazy(
    () => import("../features/customer-accounts/CustomerAccounts")
  ),
  exact: true,
  title: "Accounts",
  Icon: LockClosedIcon,
};
const licensePlatesRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.licensePlates?.readLicensePlatesTable,
  path: "/license-plates",
  component: lazy(() => import("../features/license-plates/LicensePlates")),
  exact: true,
  title: "LicensePlates",
  Icon: TableIcon,
};
const productsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.products?.readProductsTable,
  path: "/products",
  component: lazy(() => import("../features/products/Products")),
  exact: true,
  title: "Products",
  Icon: ArchiveIcon,
};
const financialTransactionsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.financialTransactions?.readFinancialTransactionsTable,
  path: "/financial-transactions",
  component: lazy(
    () => import("../features/financial-transactions/FinancialTransactions")
  ),
  exact: true,
  title: "FinancialTransactions",
  Icon: CurrencyDollarIcon,
};
const deliveryMethodsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.deliveryMethods?.readDeliveryMethodsTable,
  path: "/delivery-methods",
  component: lazy(() => import("../features/delivery-methods/DeliveryMethods")),
  exact: true,
  title: "DeliveryMethods",
  Icon: TagIcon,
};
const editDeliveryMethodsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.deliveryMethods?.updateDeliveryMethod,
  path: "/delivery-methods/:id/edit",
  component: lazy(
    () => import("../features/delivery-methods/EditDeliveryMethod")
  ),
  exact: true,
  title: "DeliveryMethods",
  Icon: TagIcon,
};
const ticketTemplatesRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.ticketTemplates?.readTicketTemplatesTable,
  path: "/ticket-templates",
  component: lazy(() => import("../features/ticket-templates/TicketTemplates")),
  exact: true,
  title: "TicketTemplates",
  Icon: TemplateIcon,
};
const salesChannelsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.salesChannels?.readSalesChannelsTable,
  path: "/sales-channels",
  component: lazy(() => import("../features/sales-channels/SalesChannels")),
  exact: true,
  title: "SalesChannels",
  Icon: TagIcon,
};
const salesChannelsEditRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.salesChannels?.updateSalesChannel,
  path: "/sales-channels/:id/edit",
  component: lazy(() => import("../features/sales-channels/EditSalesChannel")),
  exact: true,
  title: "SalesChannels",
  Icon: TagIcon,
};
const salesChannelUnitsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.salesChannelUnits?.readSalesChannelUnitsTable,
  path: "/sales-channel-units",
  component: lazy(
    () => import("../features/sales-channel-units/SalesChannelUnits")
  ),
  exact: true,
  title: "SalesChannelUnits",
  Icon: TagIcon,
};
const salesChannelUnitsEditRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.salesChannelUnits?.readSalesChannelUnitDetails,
  path: "/sales-channel-units/:id/edit",
  component: lazy(
    () => import("../features/sales-channel-units/EditSalesChannelUnit")
  ),
  exact: true,
  title: "SalesChannelUnits",
  Icon: TagIcon,
};
const addProductRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.products?.createProduct,
  path: "/products/add",
  component: lazy(() => import("../features/products/AddEditProduct")),
  exact: true,
  title: "Products",
  Icon: ArchiveIcon,
};
const editProductRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.products?.readProductDetails,
  path: "/products/:productCategoryId/:id/edit",
  component: lazy(() => import("../features/products/AddEditProduct")),
  exact: true,
  title: "Products",
  Icon: ArchiveIcon,
};
const productCategoriesRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.productCategories?.readProductCategories,
  path: "/product-categories",
  component: lazy(
    () => import("../features/product-categories/ProductCategories")
  ),
  exact: true,
  title: "ProductCategories",
  Icon: TagIcon,
};
const editProductCategoriesRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.productCategories?.readProductCategories,
  path: "/product-categories/:id/edit",
  component: lazy(
    () => import("../features/product-categories/EditProductCategory")
  ),
  exact: true,
  title: "ProductCategories",
  Icon: TagIcon,
};
const addPersonRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.customers?.createPerson,
  path: "/customers/persons/add",
  component: lazy(() => import("../features/customers/persons/AddEditPerson")),
  exact: true,
  title: "Customers",
  Icon: UserGroupIcon,
};
const editPersonRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.customers?.readPersonDetails,
  path: "/customers/persons/:id/edit",
  component: lazy(() => import("../features/customers/persons/AddEditPerson")),
  exact: true,
  title: "Customers",
  Icon: UserGroupIcon,
};
const addCompanyRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.customers?.createCompany,
  path: "/customers/companies/add",
  component: lazy(
    () => import("../features/customers/companies/AddEditCompany")
  ),
  exact: true,
  title: "Customers",
  Icon: UserGroupIcon,
};
const editCompanyRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.customers?.readCompanyDetails,
  path: "/customers/companies/:id/edit",
  component: lazy(
    () => import("../features/customers/companies/AddEditCompany")
  ),
  exact: true,
  title: "Customers",
  Icon: UserGroupIcon,
};
const editLicensePlateRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.licensePlates?.readLicensePlateDetails,
  path: "/license-plates/:id/edit",
  component: lazy(() => import("../features/license-plates/EditLicensePlate")),
  exact: true,
  title: "LicensePlates",
  Icon: TableIcon,
};
const addAccountRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.accounts?.createAccount,
  path: "/customer-accounts/add",
  component: lazy(
    () => import("../features/customer-accounts/AddEditCustomerAccount")
  ),
  exact: true,
  title: "Accounts",
  Icon: LockClosedIcon,
};
const editAccountRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.accounts?.readAccountDetails,
  path: "/customer-accounts/:id/edit",
  component: lazy(
    () => import("../features/customer-accounts/AddEditCustomerAccount")
  ),
  exact: true,
  title: "Accounts",
  Icon: LockClosedIcon,
};
const ticketDetailsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.tickets?.readTicketDetails,
  path: "/purchases/:id/details/:ticketId?",
  component: lazy(
    () => import("../features/tickets/purchases/PurchaseOverviewWrapper")
  ),
  exact: true,
  title: "Purchases",
  Icon: TicketIcon,
};
const editTicketTemplateRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.ticketTemplates?.readTicketTemplatesTable,
  path: "/ticket-templates/:id/edit",
  component: lazy(
    () => import("../features/ticket-templates/EditTicketTemplate")
  ),
  exact: true,
  title: "TicketTemplates",
  Icon: TemplateIcon,
};
const checkpointsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.checkpoints?.readCheckpointsTable,
  path: "/checkpoints",
  component: lazy(() => import("../features/checkpoints/Checkpoints")),
  exact: true,
  title: "Checkpoints",
  Icon: LocationMarkerIcon,
};
const editCheckpointRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.checkpoints?.readCheckpointDetails,
  path: "/checkpoints/:id/edit",
  component: lazy(() => import("../features/checkpoints/EditCheckpoint")),
  exact: true,
  title: "Checkpoints",
  Icon: LocationMarkerIcon,
};
const checkpointTypesRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.checkpointTypes?.readCheckpointTypesTable,
  path: "/checkpoint-types",
  component: lazy(() => import("../features/checkpoint-types/CheckpointTypes")),
  exact: true,
  title: "CheckpointTypes",
  Icon: LocationMarkerIcon,
};
const editCheckpointTypeRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.checkpointTypes?.readCheckpointTypeDetails,
  path: "/checkpoint-types/:id/edit",
  component: lazy(
    () => import("../features/checkpoint-types/EditCheckpointType")
  ),
  exact: true,
  title: "CheckpointTypes",
  Icon: LocationMarkerIcon,
};
const paymentMethodsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.paymentMethods?.readPaymentMethodsTable,
  path: "/payment-methods",
  component: lazy(() => import("../features/payment-methods/PaymentMethods")),
  exact: true,
  title: "PaymentMethods",
  Icon: CreditCardIcon,
};
const editPaymentMethodRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.paymentMethods?.readPaymentMethodDetals,
  path: "/payment-methods/:id/edit",
  component: lazy(
    () => import("../features/payment-methods/EditPaymentMethod")
  ),
  exact: true,
  title: "PaymentMethods",
  Icon: CreditCardIcon,
};
const paymentProvidersRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.paymentProviders?.readPaymentProvidersTable,
  path: "/payment-providers",
  component: lazy(
    () => import("../features/payment-providers/PaymentProviders")
  ),
  exact: true,
  title: "PaymentProviders",
  Icon: CreditCardIcon,
};
const editPaymentProviderRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.paymentProviders?.readPaymentProviderDetails,
  path: "/payment-providers/:id/edit",
  component: lazy(
    () => import("../features/payment-providers/EditPaymentProvider")
  ),
  exact: true,
  title: "PaymentProviders",
  Icon: CreditCardIcon,
};
const validitySchedulesRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.validitySchedules?.readValiditySchedulesTable,
  path: "/validity-schedules",
  component: lazy(
    () => import("../features/validity-schedules/ValiditySchedules")
  ),
  exact: true,
  title: "ValiditySchedules",
  Icon: CalendarIcon,
};
const addValidityScheduleRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.validitySchedules?.addValiditySchedule,
  path: "/validity-schedules/add",
  component: lazy(
    () => import("../features/validity-schedules/AddEditValiditySchedule")
  ),
  exact: true,
  title: "ValiditySchedules",
  Icon: CalendarIcon,
};
const editValiditySchedule: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.validitySchedules?.readValiditySchedulesDetails,
  path: "/validity-schedules/:id/edit",
  component: lazy(
    () => import("../features/validity-schedules/AddEditValiditySchedule")
  ),
  exact: true,
  title: "ValiditySchedules",
  Icon: CalendarIcon,
};
const shareholderMeetingsRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.shareholderMeetings?.readShareholderMeetingsTable,
  path: "/shareholder-meetings",
  component: lazy(
    () => import("../features/shareholder-meetings/ShareholderMeetings")
  ),
  exact: true,
  title: "ShareholderMeetings",
  Icon: CalendarIcon,
};
const addShareholderMeetingRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.shareholderMeetings?.addShareholderMeeting,
  path: "/shareholder-meetings/add",
  component: lazy(
    () => import("../features/shareholder-meetings/AddEditShareholderMeeting")
  ),
  exact: true,
  title: "ShareholderMeetings",
  Icon: CalendarIcon,
};
const editShareholderMeetingRoute: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.shareholderMeetings?.readShareholderMeetingsDetails,
  path: "/shareholder-meetings/:id/edit",
  component: lazy(
    () => import("../features/shareholder-meetings/AddEditShareholderMeeting")
  ),
  exact: true,
  title: "ShareholderMeetings",
  Icon: CalendarIcon,
};
const addShareholderMeetingParticipant: IRoute = {
  userHasCorrectPermissions: (permissions) =>
    permissions.shareholderMeetings?.addParticipant,
  path: "/shareholder-meetings/:id/register",
  component: lazy(
    () => import("../features/shareholder-meetings/AddParticipant")
  ),
  exact: true,
  title: "ShareholderMeetings",
  Icon: CalendarIcon,
};

const routes: IRoute[] = [
  productsRoute,
  productCategoriesRoute,
  customersRoute,
  customerAccountsRoute,
  bulkTicketsRoute,
  financialTransactionsRoute,
  licensePlatesRoute,
  ticketsRoute,
  ticketTemplatesRoute,
  addProductRoute,
  editProductRoute,
  productCategoriesRoute,
  editProductCategoriesRoute,
  addPersonRoute,
  editPersonRoute,
  addCompanyRoute,
  editCompanyRoute,
  editLicensePlateRoute,
  addAccountRoute,
  editAccountRoute,
  ticketDetailsRoute,
  deliveryMethodsRoute,
  editTicketTemplateRoute,
  editDeliveryMethodsRoute,
  salesChannelsRoute,
  salesChannelsEditRoute,
  salesChannelUnitsRoute,
  salesChannelUnitsEditRoute,
  checkpointsRoute,
  editCheckpointRoute,
  checkpointTypesRoute,
  editCheckpointTypeRoute,
  paymentMethodsRoute,
  editPaymentMethodRoute,
  paymentProvidersRoute,
  editPaymentProviderRoute,
  validitySchedulesRoute,
  addValidityScheduleRoute,
  editValiditySchedule,
  shareholderMeetingsRoute,
  addShareholderMeetingRoute,
  editShareholderMeetingRoute,
  addShareholderMeetingParticipant,
];

export const getHomeUrlBasedOnPermissions = (
  permissions: IPermissions
): string => {
  if (permissions.tickets?.readTicketTable) {
    return ticketsRoute.path;
  }

  if (permissions.tickets?.createBulkPurchase) {
    return bulkTicketsRoute.path;
  }

  if (permissions.customers?.readCustomerTable) {
    return customersRoute.path;
  }

  if (permissions.accounts?.readAccountsTable) {
    return customerAccountsRoute.path;
  }

  if (permissions.licensePlates?.readLicensePlatesTable) {
    return licensePlatesRoute.path;
  }

  if (permissions.products?.readProductsTable) {
    return productsRoute.path;
  }

  if (permissions.productCategories?.readProductCategories) {
    return productCategoriesRoute.path;
  }

  if (permissions.financialTransactions?.readFinancialTransactionsTable) {
    return financialTransactionsRoute.path;
  }

  if (permissions.deliveryMethods?.readDeliveryMethodsTable) {
    return deliveryMethodsRoute.path;
  }

  if (permissions.ticketTemplates?.readTicketTemplatesTable) {
    return ticketTemplatesRoute.path;
  }

  if (permissions.salesChannels?.readSalesChannelsTable) {
    return salesChannelsRoute.path;
  }

  if (permissions.salesChannelUnits?.readSalesChannelUnitsTable) {
    return salesChannelUnitsRoute.path;
  }

  if (permissions.checkpoints?.readCheckpointsTable) {
    return checkpointsRoute.path;
  }

  if (permissions.checkpointTypes?.readCheckpointTypesTable) {
    return checkpointTypesRoute.path;
  }

  if (permissions.paymentMethods?.readPaymentMethodsTable) {
    return paymentMethodsRoute.path;
  }

  if (permissions.paymentProviders?.readPaymentProvidersTable) {
    return paymentProvidersRoute.path;
  }

  if (permissions.validitySchedules?.readValiditySchedulesTable) {
    return validitySchedulesRoute.path;
  }

  if (permissions.shareholderMeetings?.readShareholderMeetingsDetails) {
    return shareholderMeetingsRoute.path;
  }

  return "/";
};

export default routes;
